import Modal from './modal.js'

class Custom {
  constructor() {
    this.hideelement()
  }

  hideelement() {
    const myModalEl = document.querySelector('#modalSheet')
    const modal = new Modal(myModalEl)
    modal.hide()
  }
}

export default Custom
